// tslint:disable: object-literal-sort-keys

import type { CookieConsentConfig } from 'vanilla-cookieconsent';
import * as CookieConsent from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import '../stylesheets/cookieconsent.scss';

interface IWindowCookieData {
  translations: {
    accept_all: string;
    close: string;
    cookie_preferences: string;
    cookies: string;
    description: string;
    domain: string;
    expiration: string;
    more_information: string;
    name: string;
    reject_all: string;
    save_settings: string;
    settings: string;
    strictly_necessary_cookies: string;
    these_cookies_are_essential: string;
    as_soon_as_browser_window_is_closed: string;
    allows_us_to_associate_file_uploads: string;
    allows_our_load_balancer: string;
    saves_your_decisions: string;
    analytics_and_performance_cookies: string;
    these_cookies_remember_the_choices: string;
    internal_metrics_collector: string;
    six_months: string;
    two_years: string;
    four_hours: string;
  };
  customContent: {
    notice: string;
    explanation: string;
    further_information?: string;
  };
}

interface IWindowWithCookieData extends Window {
  cookieConsentData?: IWindowCookieData;
  cookieConsentCustomConfig?: CookieConsentConfig;
}

function clearUnacceptedCookies() {
  // We want to clear cookies on every page navigation
  // but the library doesn't expose an ability to do this (yet)
  // this method is only in our own fork
  //
  // NOTE: Commenting out for now as this is breaking QR codes and attribution
  // CookieConsent.performAutoclearCookies();
}

function createNewCookieConsent() {
  const { cookieConsentData } = window as unknown as IWindowWithCookieData;
  const { cookieConsentCustomConfig } = window as unknown as IWindowWithCookieData;

  if (cookieConsentCustomConfig) {
    CookieConsent.run(cookieConsentCustomConfig);
  } else {
    const { translations, customContent } = cookieConsentData;

    const sections: CookieConsent.Section[] = [
      {
        description: customContent.explanation,
      },
    ];

    sections.push({
      title: translations.strictly_necessary_cookies,
      description: translations.these_cookies_are_essential,
      linkedCategory: 'necessary',
      cookieTable: {
        headers: {
          name: translations.name,
          domain: translations.domain,
          expiration: translations.expiration,
          desc: translations.description,
        },
        body: [
          {
            name: '_pinpoint_session',
            domain: 'pinpointhq.com',
            expiration: translations.as_soon_as_browser_window_is_closed,
            desc: translations.allows_us_to_associate_file_uploads,
          },
          {
            name: 'LSW_WEB',
            domain: window.location.hostname,
            expiration: translations.as_soon_as_browser_window_is_closed,
            desc: translations.allows_our_load_balancer,
          },
          {
            name: 'LSW_WEB',
            domain: 'app.pinpointhq.com',
            expiration: translations.as_soon_as_browser_window_is_closed,
            desc: translations.allows_our_load_balancer,
          },
          {
            name: 'cc_cookie',
            domain: window.location.hostname,
            expiration: translations.six_months,
            desc: translations.saves_your_decisions,
          },
        ],
      },
    });

    sections.push({
      title: translations.analytics_and_performance_cookies,
      description: translations.these_cookies_remember_the_choices,
      linkedCategory: 'analytics',
      cookieTable: {
        headers: {
          name: translations.name,
          domain: translations.domain,
          expiration: translations.expiration,
          desc: translations.description,
        },
        body: [
          {
            name: 'ahoy_visitor',
            domain: 'pinpointhq.com',
            expiration: translations.two_years,
            desc: translations.internal_metrics_collector,
          },
          {
            name: 'ahoy_visit',
            domain: 'pinpointhq.com',
            expiration: translations.four_hours,
            desc: translations.internal_metrics_collector,
          },
        ],
      },
    });

    if (customContent.further_information) {
      sections.push({
        title: translations.more_information,
        description: customContent.further_information,
      });
    }

    const cookieConsentSettings: CookieConsentConfig = {
      guiOptions: {
        consentModal: {
          position: 'bottom right',
          equalWeightButtons: true,
          layout: 'box wide',
        },
      },
      hideFromBots: false,
      lazyHtmlGeneration: false,
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {
          autoClear: {
            cookies: [
              {
                name: /^_ga/,
              },
              {
                name: /^ahoy_/,
              },
              {
                name: /^_gid/,
              },
            ],
          },
        },
      },
      language: {
        default: 'en', // always 'en' because we are passing our strings from the view
        autoDetect: null,
        translations: {
          en: {
            consentModal: {
              title: translations.cookies,
              description: customContent.notice,
              acceptAllBtn: translations.accept_all,
              acceptNecessaryBtn: translations.reject_all,
              showPreferencesBtn: translations.settings,
            },
            preferencesModal: {
              title: translations.cookie_preferences,
              savePreferencesBtn: translations.save_settings,
              acceptAllBtn: translations.accept_all,
              acceptNecessaryBtn: translations.reject_all,
              closeIconLabel: translations.close,
              sections,
            },
          },
        },
      },
      autoClearCookies: true,
      manageScriptTags: true,
      onFirstConsent() {},
      onConsent: () => {
        if (CookieConsent.acceptedCategory('analytics')) {
          const event = new Event('cookieconsent-analytics');
          document.dispatchEvent(event);
        }
      },
      onChange() {},
    };

    CookieConsent.run(cookieConsentSettings);
  }

  setTimeout(() => {
    // wipe the cookies on every browse if we have saved any decision
    clearUnacceptedCookies();
  }, 1000);
}

function hideCookieNotice() {
  // allows us to override the test env check and show the cookie notice.
  if (window?.location?.search === '?show_cookies_notice=true') return false;

  // don't show the notice in test env
  if ((window as any)?.railsEnv === 'test') return true;

  // don't show the notice on login endpoints
  const excludedPathNames = ['login', 'admin', 'platform', 'extensions'];
  const pathName = window.location.pathname;
  if (excludedPathNames.includes(pathName.split('/')[1])) return true;

  return false;
}

document.addEventListener('turbolinks:load', () => {
  if (hideCookieNotice()) return;

  // we need a new cookieconsent every time turbolinks destroys our old one.
  createNewCookieConsent();
});
